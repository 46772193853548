const pino = require('pino')

const isBrowser = () => ![typeof window, typeof document].includes('undefined')

const pinoLogger = pino({
  formatters: {
    level(level) {
      return { level }
    },
  },
  messageKey: 'message',
  browser: {
    asObject: true,
    transmit: {
      send: (level, logEvent) => {
        if (!isBrowser()) {
          return
        }

        const headers = {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          type: 'application/json',
        }

        let url = null
        if (window?.location) {
          url = window.location.pathname + window.location.search
        }

        const blob = new Blob(
          [
            JSON.stringify({
              level,
              logEvent,
              url,
            }),
          ],
          headers
        )
        navigator.sendBeacon('/next-api/log', blob)
      },
    },
  },
  redact: {
    paths: ['req.headers.cookie', 'hostname'],
    remove: true,
  },
})

const clientSideRequestLogger = pinoLogger.child({ isClientSideRequest: true })

const serverLogger = require('pino-http')({
  logger: pinoLogger,
  autoLogging: {
    ignorePaths: [
      /\/static\/*/,
      /\/public\/*/,
      /\/\.well-known\/*/,
      /\/lib\/*/,
      '/next-api/log', // Don't auto-log from this route as we manually do it in logRoute
    ],
  },
  customLogLevel: (res, err) => {
    if (res.statusCode >= 500 || err) {
      // Always log errors
      return 'error'
    }

    if (res.isServerSideRender) {
      // Log info for server side renders that aren't errors (excludes 'robots.txt' etc.)
      return 'info'
    }

    return 'silent'
  },
  customProps: (req, res) => {
    const props = {
      statusCode: res.statusCode,
      url: req.url,
    }

    return props
  },
})

// Logs via the standard pino logger
const captureError = (err, ...rest) => {
  pinoLogger.error(err, ...rest)
}

module.exports = {
  captureError,
  logger: pinoLogger,
  clientSideRequestLogger,
  serverLogger,
}
