import getConfig from 'next/config'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { ApolloLink } from '@apollo/client'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const createTimeoutLink = () => {
  const timeout = getTimeoutToUse(
    publicRuntimeConfig.GRAPHQL_TIMEOUT_MS,
    serverRuntimeConfig.INTERNAL_GRAPHQL_TIMEOUT_MS
  )

  if (timeout) {
    // This casting is temporary as timeout link is outdated and relies on an older
    // version of ApolloLink that doesn't have the onError and setOnError abstract methods
    // thus it's not a valid ApolloLink instance however it still works as a link
    // This cast should be removed once timeout link is replaced with a more up-to-date
    // link such as RetryLink see https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
    return new ApolloLinkTimeout(timeout) as unknown as ApolloLink
  }

  return null
}

const getTimeoutToUse = (timeoutMs, internalTimeoutMs) => {
  if (internalTimeoutMs && parseInt(internalTimeoutMs, 10) > 0) {
    // Server side
    return parseInt(internalTimeoutMs, 10)
  }

  if (timeoutMs && parseInt(timeoutMs, 10) > 0) {
    // Client side
    return parseInt(timeoutMs, 10)
  }

  // No timeout
  return null
}

export { getTimeoutToUse }
export default createTimeoutLink
