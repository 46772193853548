import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useRef } from 'react'
import { clientSideRequestLogger } from 'lib/logger'

import { useRouter } from 'next/router'

const RequestStatusContext = createContext()

const STATUS_CODES = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}

const RequestLogging = ({ initialSSR, children }) => {
  const { events, asPath } = useRouter()

  // On the client there aren't really status codes, but we want to track
  // when our 404/500/errorboundary are triggered in the same way as SSR.
  // Keep track of a status code that we reset after every page load
  const statusCode = useRef(STATUS_CODES.SUCCESS)
  const setStatusCode = (newStatusCode) => {
    statusCode.current = newStatusCode
  }

  const logRequest = () => {
    const loggerWithStatusCode = clientSideRequestLogger.child({
      clientSideStatusCode: statusCode.current,
    })

    if (statusCode.current === STATUS_CODES.INTERNAL_SERVER_ERROR) {
      loggerWithStatusCode.error('error navigating to page %s', asPath)
    } else {
      loggerWithStatusCode.info('navigated to page %s', asPath)
    }
  }

  const resetStatusCode = () => setStatusCode(STATUS_CODES.SUCCESS)

  useEffect(() => {
    if (!initialSSR) {
      // If we SSR then logging took place on the server
      logRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    events.on('routeChangeStart', resetStatusCode)
    events.on('routeChangeComplete', logRequest)

    return () => {
      events.off('routeChangeStart', resetStatusCode)
      events.off('routeChangeComplete', logRequest)
    }
  })

  return (
    <RequestStatusContext.Provider value={{ setStatusCode }}>
      {children}
    </RequestStatusContext.Provider>
  )
}

const useSetStatusCode = () => {
  const { setStatusCode } = useContext(RequestStatusContext)
  return (statusCode) => setStatusCode(statusCode)
}

RequestLogging.propTypes = {
  children: PropTypes.node,
  initialSSR: PropTypes.bool,
}

export { STATUS_CODES, useSetStatusCode, RequestStatusContext }
export default RequestLogging
