const testIds = {
  loginUsername: 'login-username',
  loginPassword: 'login-password',
  loginSubmit: 'login-submit',
  loginModal: 'login-modal',
  slideItem: 'slide-item',
  slide: 'slide',
  video: 'video',
  manageEvents: 'manage-events',
  profileTypeComponent: 'profile-type-component',
  authenticatedPerson: 'authenticated-person',
  myAccount: 'my-account',
  logout: 'logout',
  ticketedEvent: 'ticketed-event',
  nonTicketedEvent: 'non-ticketed-event',
  eventListingHeading: 'event-listing-heading',
  eventDetail: 'event-detail',
  ticketsIframe: 'tickets-iframe',
  yearNavigation: 'year-navigation',
  filterModalSearchInput: 'filter-modal-search-input',
  cookieConsentTopBanner: 'cookie-consent-top-banner',
  cookieConsentBannerCloseButton: 'cookie-consent-banner-close-button',
  current: 'current',
  archive: 'archive',
  combined: 'combined',
  basketIframe: 'basket-iframe',
  RAProWeb: 'ra-pro-web',
  kebabMenu: 'kebab-menu',
  entireDemoTickets: 'entire-demo-tickets',
  unsubscribePageContent: 'unsubscribe-page-content',
  raProEventSummaryStartTime: 'ra-pro-event-summary-start-time',
  raProEventSummaryEndTime: 'ra-pro-event-summary-end-time',
  eventListingCard: 'event-listing-card',
  mediaPlayer: 'media-player',
  flagSvg: 'flag-svg',
  globalNav: 'global-nav',
  raProLogo: 'ra-pro-logo',
  playerWidgetContainer: 'player-widget-container',
  eventUpcomingCard: 'event-upcoming-card',
  appleSignInButton: 'apple-signin-button',
  appleSignInScript: 'apple-signin-script',
  profileAboutSubSection: 'profile-about-subsection',
  buttonLink: 'button-link',
  podcastExchangeCard: 'podcast-exchange-card',
  countryFilterItemButtonLink: 'country-filter-item-button-link',
  notificationsIndicator: 'notifications-indicator',
  featureFilmSeriesMarkup: 'feature-series-markup',
  eventPickCard: 'event-pick-card',
  ips: 'ips',
  paymentMarkup: 'payment-markup',
  paymentMethodBlock: 'payment-method-block',
  orderSummary: 'order-summary',
  preReg: 'pre-reg',
  groupedHeaderHeading: 'grouped-header-heading',
  metaText: 'meta-text',
  monthlyList: 'monthly-list',
  monthlyListSticky: 'monthly-list-sticky',
  fieldArray: 'field-array',
  formikForm: 'formik-form',
  hyperwalletDropIn: 'hyperwallet-drop-in',
  submitButton: 'submit-button',
  savedEventsButton: 'saved-events-button',
  latestNews: 'latest-news',
  headlineNews: 'headline-news',
  stepsList: 'steps-list',
  defaultLoadingSpinner: 'default-loading-spinner',
  modalChildrenWrapper: 'modal-children-wrapper',
  cornerNav: 'corner-nav',
  cornerNavToggle: 'corner-nav-toggle',
  preventBodyScrolling: 'prevent-body-scrolling',
  poweredByRALink: 'powered-by-ra-link',
  fullWidthImage: 'full-width-image',
  beaconJs: 'beacon-js',
  diffPartsAdded: 'diff-add-',
  diffPartsRemoved: 'diff-remove-',
  horizontalNewsListing: 'horizontal-news-listing',
  genericNoResults: 'generic-no-results',
  groupedNews: 'grouped-news',
  groupedNewsItem: 'grouped-news-item',
  ad: (adUnit) => `ad-${adUnit}`,
  popularNewsVertical: 'popular-news-vertical',
  latestNewsCard: 'latest-news-card',
  defaultLoadingSpiner: 'default-loading-spinner',
  youtubePlayer: 'youtube-player',
  artistImage: 'artist-image',
  leftDiff: 'left-diff',
  rightDiff: 'right-diff',
  infiniteScrollPaginationSEO: 'infinite-scroll-pagination-seo',
  artistsLineup: 'artists-lineup',
  interestedCount: 'interested-count',
  confirmedOrderModal: 'confirm-order-modal',
  confirmedOrderModalCloseButton: 'confirm-order-modal-close-button',
  buyTicketsButton: 'buy-tickets-button',
}

export default testIds
