import { themes, colors } from '@resident-advisor/design-system'
import zIndex from './zIndex'
import opacity from './opacity'
import animation from './animation'
import shared from './shared'

export default {
  ...shared,
  ...themes.dark,
  zIndex,
  opacity,
  animation,

  search: {
    borderColor: colors.grey['20'],
    activeColor: colors.coral,
  },

  filterList: {
    separatorColor: colors.grey['20'],
    backgroundColor: colors.black,
  },
  filter: {
    backgroundColor: colors.grey['10'],
  },

  navigation: {
    activeColor: colors.coral,
    hoverColor: colors.coral,
    backgroundColor: colors.black,
  },

  headerWrapper: {
    backgroundColor: colors.grey['10'],
  },

  coloredHeader: {
    backgroundColor: colors.coral,
  },

  guidePageBanner: {
    backgroundColor: colors.black,
  },

  subNav: {
    backgroundColor: colors.black,
    fontWeight: themes.light.fontWeights.normal,
    linkColor: colors.grey['10'],
    hoverColor: colors.coral,
    defaultColor: colors.grey['40'],
    selectedColor: colors.grey['80'],
    bottom: '18px',
  },

  soundcloud: {
    defaultColor: colors.coral,
  },

  bandcampAlbum: {
    defaultColor: colors.coral,
    backgroundColor: colors.black,
  },

  bandcampTrack: {
    defaultColor: colors.coral,
    backgroundColor: colors.black,
  },

  mobileModal: {
    backgroundColor: colors.white,
  },

  login: {
    backgroundColor: colors.black,
    iconColor: colors.grey['80'],
    facebookColor: '#3b5998',
  },

  noResults: {
    icon: colors.grey['70'],
  },

  sideModal: {
    backgroundColor: colors.grey['10'],
    opacity: opacity.modalBackground,
    textColor: 'tertiary',
  },

  popover: {
    backgroundColor: colors.grey['10'],
    borderColor: colors.grey['20'],
  },

  radio: {
    backgroundColor: colors.grey['80'],
  },
  eventsWidget: {
    backgroundColor: colors.grey['10'],
  },
}
